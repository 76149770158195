<template>
  <div class="main-box">
    <div style="height:60px"></div> <!-- 占位 -->
    <!-- 面包屑 -->
    <div class="menu-inner">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/homework/homeworkList">作业列表</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item style="color: #15b7dd">{{data.homeworkTitle}}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <!-- 作业编辑区 -->
    <div class="homework-inner" v-show="!dataLoad">
      <!-- 作业名称 -->
      <h4 class="title">{{data.homeworkTitle}}</h4>

      <!-- 作业详情 -->
      <div class="home-info">
        <div class="info info-l">
          <div class="top-box">
            <div class="name-all">
              <img src="./icon/icon1.png" alt="">作业详情
            </div>
          </div>
          <div class="bottom-box" v-html="data.libraryContent">
          </div>
        </div>
        <div class="info info-r">
          <div class="top-box">
            <div class="name-all">
              <img src="./icon/icon1.png" alt="">提交说明
            </div>
          </div>
          <div class="bottom-box">
            <div class="explain" v-html="data.answerNote"></div>
            <img class="bg-img" src="./icon/icon2.png" alt="">
          </div>
        </div>
      </div>

      <!-- 作业附件 -->
      <File-List :list="data.attachment" :isDown="1" />
    </div>
    <div class="homework-inner" v-show="!dataLoad">
      <div class="home-submit">
        <!-- 作业上传区 -->
        <div v-if="fildShow" style="margin-bottom:28px">
          <div class="name-all">
            <img src="./icon/icon14.png" alt="">上传附件
            <span class="tips">*附件必须以{{limitTxt}}的方式提交</span>
          </div>
          <div class="upload">
            <!-- :accept="limitSuffix" -->
            <a-upload-dragger
              name="file"
              :multiple="false"
              :showUploadList="false"
              :disabled="singleLoad"
              :before-upload="beforeUpload"
              :customRequest="handleChange"
            >
              <a-icon v-if="loading" type="loading" :style="{fontSize: '25px', color: '#15B7DD'}"/>
              <img v-else class="upload-img" src="./icon/upload-icon.png" alt="">
              <p class="tips">拖动文件 或 在此点击<a>上传</a></p>
            </a-upload-dragger>
            <div class="progress" v-if="singleLoad" >
              <p>上传速率：{{speed}}Mb/s</p>
              <a-progress :percent="progress"  />
            </div>
          </div>
          <div class="file-list">
            <div class="file-li" v-for="(item,index) in fileList" :key="item.url">
              <div class="sidl-l" title="点击可在线预览" @click="onOpenFile(item.url)">
                <img :src="getSuffix(item.url)" alt="">
                <span>{{item.name}}</span>
              </div>
              <div class="sidl-r">
                <div class="btn"  @click="onclickImg(index)">
                  <img :class="{ 'img-revolve' : item.loading }" src="./icon/icon3.png" alt="">
                  <span>重新上传</span>
                </div>
                <div class="btn" @click="onFileDel(index)">
                  <img src="./icon/icon4.png" alt="">
                  <span>删除</span>
                </div>
              </div>
            </div>
            <input
              type="file"
              ref="file"
              @change="preview"
              :accept="limitSuffix"
              style="display: none"
            />
          </div>
        </div>
        <!-- 作业编写区 -->
        <div v-if="txtInputShow">
          <div class="name-all">
            <img src="./icon/icon15.png" alt="">作业文本
          </div>
          <div class="upload">
            <a-textarea
            v-model="content"
            placeholder="请输入作业内容文本"
            :auto-size="{ minRows: 4 }"></a-textarea>
          </div>
        </div>

        <!-- 补充资料 -->
        <!-- 补充资料上传区 -->
        <div style="margin-top:28px" v-if="data.supplyFlag == 1">
          <div class="name-all">
            <img src="./icon/attachIcon.png" alt="">上传补充资料
            <span class="tips">*非必填项，若有同意书等补充资料可上传至此处。</span>
          </div>
          <div class="upload">
            <!-- :accept="limitSuffix" -->
            <a-upload-dragger
              name="file"
              :multiple="false"
              :showUploadList="false"
              :disabled="attachsingleLoad"
              :before-upload="attachBeforeUpload"
              :customRequest="attachHandleChange"
            >
              <a-icon v-if="attachLoading" type="loading" :style="{fontSize: '25px', color: '#15B7DD'}"/>
              <img v-else class="upload-img" src="./icon/upload-icon.png" alt="">
              <p class="tips">拖动文件 或 在此点击<a>上传</a></p>
            </a-upload-dragger>
            <div class="progress" v-if="attachsingleLoad" >
              <p>上传速率：{{attachspeed}}Mb/s</p>
              <a-progress :percent="attachprogress"  />
            </div>
          </div>
          <div class="file-list">
            <div class="file-li" v-for="(item,index) in attachList" :key="item.url">
              <div class="sidl-l" title="点击可在线预览" @click="onOpenFile(item.url)">
                <img :src="getSuffix(item.url)" alt="">
                <span>{{item.name}}</span>
              </div>
              <div class="sidl-r">
                <div class="btn"  @click="onclickAttachImg(index)">
                  <img :class="{ 'img-revolve' : item.loading }" src="./icon/icon3.png" alt="">
                  <span>重新上传</span>
                </div>
                <div class="btn" @click="onattachFileDel(index)">
                  <img src="./icon/icon4.png" alt="">
                  <span>删除</span>
                </div>
              </div>
            </div>
            <input
              type="file"
              ref="attachfile"
              @change="attachPreview"
              style="display: none"
            />
          </div>
        </div>

        <!-- 提交按钮 -->
        <div class="submit-btn">
          <span class="cancel-btn" @click="onSubmitWork(1)">保存编辑</span>
          <span class="all-btn-small" @click="onSubmitWork(3)">提交作业</span>
        </div>
      </div>
    </div>
    <div class="homework-inner" v-show="dataLoad">
      <a-spin class="sping" />
    </div>
  </div>
</template>

<script>
import { openFile,getSuffix } from '@/unit/fun.js'
import { uploadObject } from "@/unit/cosUpload.js";

import FileList from "./FileList.vue";
import attachUpload from "./components/upload.vue";

export default {
  // 可用组件的哈希表
  components: { FileList },
  mixins: [attachUpload],
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      answerId: 0,
      dataLoad:false,
      loading:false,
      singleLoad:false,
      shaking: false,
      data:{},

      content:'',
      fileList:[], // 附件列表 
      fileIndex: 0,  // 附件索引

      progress: 0, // 进度
      speed:0, // 速率

      txtInputShow: false, //是否显示文本输入
      fildShow: false, //是否需要上传文件
      fileMap:[ // 文件类型格式
        {key:1, txt:'视频', suffix:'mp4,MP4'},
        {key:2, txt:'图片', suffix:'jpg,png,gif,jpeg,heic'},
        {key:3, txt:'电子文件（如word、pdf）', suffix:'txt,doc,docx,pdf'},
      ],
      limitTxt:'', // 限制作业格式文字
      limitSuffix:'', // 限制作业格式后缀
      fileReg: '', // 正则
      
    }
  },
  // 事件处理器
  methods: {
    // 删除上传的附件
    onFileDel(i){
      this.fileList.splice(i, 1)
    },

    //点击图片唤起本地文件夹
    onclickImg(index) {
      // 防抖
      if(!this.singleLoad){
        this.$refs.file.value = null
        this.fileIndex = index
        this.$refs.file.click();
      } else {
          this.$message.warning('正在上传中，请稍后重试');
      }
    },

    //重新上传文件
    preview(e) {

      let _this = this;
      let file = e.target.files[0];
      if (!file) {
        return;
      }

      // 文件格式校验
      if (!this.fileReg.test(file.name)) {
        this.$message.warning('请按要求提示上传正确格式的作业附件');
        return false
      }

      const isLt2M = file.size / 1024 / 1024 < 5120;
      if (!isLt2M) {
        this.$message.error("作业附件大小需在5GB之内");
        return
      }

      _this.progress = 0
      _this.speed = 0

      _this.singleLoad = true
      _this.fileList[_this.fileIndex].loading = true

      // 腾讯云对象存储上传文件
      const bucket = 'user/homework/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）

      uploadObject(file, bucket, this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          _this.$message.success('上传成功');
          _this.$set(_this, 'singleLoad', false)
          
          let obj = {
            name: fileName,
            url: url,
            loading:false,
            type: this.returnType(url)
          }

          // 更新某个已上传的附件信息
          _this.$set(_this.fileList, _this.fileIndex, obj)
        } else {
          _this.$message.error("上传失败");
        }
      });
    },

    // 文件上传前校验
    beforeUpload(file) {
      if (!this.fileReg.test(file.name)) {
        this.$message.warning('请按要求提示上传正确格式的作业附件');
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 5120;
      if (!isLt2M) {
        this.$message.error("作业附件大小需在5GB之内");
      }
      return isLt2M;
    },
    
    // 附件上传
    handleChange(fileData) {
      this.progress = 0
      this.speed = 0
      this.loading = true
      this.singleLoad = true

      // 腾讯云对象存储上传文件
      const bucket = 'user/homework/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      uploadObject(fileData.file, bucket, this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.$message.success('上传成功');
          this.fileList.push({
            url: url,
            name: fileName,
            loading: false,
            type: this.returnType(url)
          })
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false
        this.singleLoad = false
      });

    },
    

    // // 附件类型返回
    // returnType(url) {
    //   let lastIndex = url.lastIndexOf(".");
    //   let suffix = url.substring(lastIndex + 1);

    //   var imgFiles = ["jpg", "png", "gif", "jpeg"]; // 图片文件
    //   var videoFiles = ["mp4", 'MP4', 'Mp4', 'mP4']; // 视频文件
    //   var officeFiles = ["pdf", "doc", "docx", "xlsx", "xls", "txt"]; // 办公文件

    //   var type = "";
    //   if (imgFiles.indexOf(suffix) != -1) {
    //     type = 1;
    //   } else if (videoFiles.indexOf(suffix) != -1) {
    //     type = 2;
    //   } else if (officeFiles.indexOf(suffix) != -1) {
    //     type = 3;
    //   }
    //   return type;
    // },

    // // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
      this.progress = progress
      this.speed = speed
    },

    // 打开文件
    onOpenFile(url){
      openFile(url)
    },

    // 解析文件后缀返回对应图片
    getSuffix(string){
      return getSuffix(string)
    },


    // 作业提交
    onSubmitWork(type){
      // 防抖
      if(this.shaking) return
      
      // type 1 为编辑，不用校验
      if(type == 3){
        // 校验是否上传附件
        if(this.fildShow){
          if(!this.fileList.length){
            this.$message.warning('请上传 ' + this.limitTxt + ' 格式作业附件');
            return
          }

          // 筛选出学员已上传的文件格式 后缀名做去重处理
          let suffixArry = []
          this.fileList.forEach(element => {
            let lastIndex = element.url.lastIndexOf('.');
            let suffix = element.url.substring(lastIndex+1)
            suffixArry.push(suffix)
          });

          suffixArry = suffixArry.filter((item, index, array) => {
            return array.indexOf(item) === index
          })

          // 筛选出需要上传的文件格式类型
          let arr = this.data.submitFormatList.filter((item) => {
            return item != 0;
          });
          let obj = [
            {
              key:1,
              arry:['mp4', 'MP4', 'Mp4', 'mP4'],
              tips: '缺少视频文件作业',
              isRturn:true,
            },
            {
              key:2,
              arry: ['jpg', 'png', 'gif', 'jpeg', 'heic'],
              tips: '缺少图片格式作业',
              isRturn:true,
            },
            {
              key:3,
              arry: ['txt', 'doc', 'docx', 'pdf'],
              tips: '缺少电子附件作业(如word、pdf)',
              isRturn:true,
            }
          ]
          var newList = obj.filter((item) =>
            arr.includes(item.key)
          );


          // 检索是否满足上传条件
          newList.forEach(uItem => {
            (uItem.arry).forEach(cItem => {
              suffixArry.forEach(submit => {
                if(cItem == submit){
                  uItem.isRturn = false
                }
              });
            });
          });
          
          // 校验上传的附件是否满足设置的附件格式
          let isNext = true
          for(let i=0; i < newList.length; i++){
            if(newList[i].isRturn){
              isNext = false
              this.$message.warning(newList[i].tips);
              break
            }
          }

          if(!isNext){
            return
          }
        }

        // 校验文本
        if(this.txtInputShow && !this.content){
          this.$message.warning('请输入作业内容文本');
          return
        }
      }
      let attachlist = []
      this.fileList.forEach(element => {
        attachlist.push({
          name: element.name,
          type: element.type,
          url: element.url
        })
      });

      // 补充资料文件列表
      let supplyAttach = [];
      this.attachList.forEach(element => {
        supplyAttach.push({
          name: element.name,
          type: element.type,
          url: element.url
        })
      });
      this.shaking = true
      let params = {
        answerId: this.answerId,
        attachlist: JSON.stringify(attachlist),
        supplyAttach: JSON.stringify(supplyAttach),
        content: this.content,
        status: type
      }
      this.$ajax({
        url: '/hxclass-pc/homework/submit',
        method: 'POST',
        params
      }).then(res=>{
        this.shaking = false
        if(res.code == 200 && res.success) {
          this.$message.success(type==1 ? '暂存成功' : '提交成功');
          this.$router.push({
            path: "/homework/homeworkList"
          });
        }else {
          this.$message.error(res.message);
        }
      })
    },

    // 获取详情数据
    getData() {
      this.dataLoad = true
      this.$ajax({
        url: '/hxclass-pc/homework/userAnswer/details',
        params: {
          answerId: this.answerId
        }
      }).then(res=>{
        this.dataLoad = false
        if(res.code == 200 && res.success) {
          res.data.attachment = res.data.attachment ? JSON.parse(res.data.attachment) : [];
          this.data = res.data;

          // 作业是否需要文本提交
          this.txtInputShow = (res.data.submitFormatList.indexOf(0) == -1 ? false : true)


          // 筛选出需要上传的文件格式类型
          let arr = res.data.submitFormatList.filter((item) => {
            return item != 0;
          });
          this.fildShow = (arr.length ? true : false)
          if(this.fildShow){
            let limitTxt = [] // 存放文件
            let limitSuffix = []
            this.fileMap.forEach(element => {
              if(arr.indexOf(element.key) != -1 ){
                limitTxt.push(element.txt)
                limitSuffix.push(element.suffix)
              }
            });
            let str = limitTxt.toString()
            this.limitTxt = str.replace(/,/g,'、')  // 后缀文字
            

            let strArry = (limitSuffix.toString()).split(',')
            limitSuffix = []
            strArry.forEach((element,index) => {
              limitSuffix.push('.' + element)
            });

            this.limitSuffix = limitSuffix.toString()  // 上传后缀格式限制
            this.funReg(strArry)


            this.content = res.data.answerContent
            this.fileList = res.data.attachlist ? JSON.parse(res.data.attachlist) : []
            // this.fileList = res.data.attachlist ? JSON.parse(res.data.attachlist) : []
          }
        }else {
          this.$message.error(res.message);
        }
      })
    },
    // 动态生成正则
    funReg(limitSuffix){
      limitSuffix = limitSuffix.toString()
      this.fileReg = new RegExp(`.*\.${limitSuffix.replace(/,/g,'|')}`)
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.answerId = this.$AES.decode_data(this.$route.query.answerId);
    this.getData()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.menu-inner{
  /deep/ .ant-breadcrumb{
    line-height: 60px;
  }
}

.homework-inner{
  min-height: 200px;
  padding: 30px;
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 12px;
  position: relative;
  .name-all{
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    img{
      width: 16px;
      margin-top: -2px;
      margin-right: 3px;
    }
    .tips{
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #F90000;
      line-height: 15px;
    }
  }
  .title{
    font-size: 20px;
    font-weight: 500;
    color: #15B7DD;
    line-height: 23px;
    &::before{
      content: '';
      display: inline-block;
      width: 3px;
      height: 16px;
      background: #15B7DD;
      border-radius: 2px;
      margin-right: 4px;
    }
  }

  .home-info{
    margin-top: 16px;
    display: flex;
    .info{
      border-radius: 10px;
      border: 1px solid #F2F2F2;
      overflow: hidden;
      .top-box{
        padding: 12px;
        background: #FCFBFC;
      }

      .bottom-box{
        height: 200px;
        padding: 12px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 25px;
        /deep/strong{
          font-weight: bold;
        }
        /deep/em{
          font-style: italic;
        }
        /deep/img{
          max-width: 100%;
        }
        /deep/.remarks{
          max-width: 100%;
          height: auto !important;
          -webkit-line-clamp: 10000 !important;
        }
        /deep/ video{
          width: 100%;
        }
      }
    }
    .info-l{
      width: 815px;
      margin-right: 20px;
      .bottom-box{
        margin-bottom: 12px;
        padding-bottom: 0;
        overflow-y: scroll;
      }
      /* 设置滚动条的样式 */
      .bottom-box::-webkit-scrollbar {
        width:4px;
      }
      /* 滚动槽 */
      .bottom-box::-webkit-scrollbar-track {
        -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
        border-radius:0;
        background:rgba(255, 255, 255, 0.1);
      }
      /* 滚动条滑块 */
      .bottom-box::-webkit-scrollbar-thumb {
        border-radius:10px;
        background:rgba(246,246,252,1);
        -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
      }
    }
    .info-r{
      flex: 1;
      .bottom-box{
        position: relative;
        overflow: hidden;
        .explain{
          height: 100px;
          overflow-y: scroll;
          padding-right: 3px;
        }
        /* 设置滚动条的样式 */
        .explain::-webkit-scrollbar {
          width:4px;
        }
        /* 滚动槽 */
        .explain::-webkit-scrollbar-track {
          -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
          border-radius:0;
          background:rgba(255, 255, 255, 0.1);
        }
        /* 滚动条滑块 */
        .explain::-webkit-scrollbar-thumb {
          border-radius:10px;
          background:rgba(246,246,252,1);
          -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
        }
        .bg-img{
          position: absolute;
          right: 0;
          bottom: 0;
          width: 124px;
        }
      }
    }
  }
  .home-submit{
    margin-top: 24px;
    border-radius: 10px;
    padding: 12px;
    border: 1px solid #F2F2F2;
    .upload{
      padding: 16px;
      padding-bottom: 0;
      /deep/ .ant-upload.ant-upload-drag{
        background: #ffffff;
      }
      /deep/ .ant-upload{
        padding: 20px 0;
      }
      .upload-img{
        width: 33px;
      }
      .tips{
        margin-top: 5px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
      }
      a{
        text-decoration: underline;
      }
      .progress{
        display: flex;
        align-items: center;
        height: 30px;
        p{
          font-size: 14px;
          white-space:nowrap;
          margin-right: 10px;
        }
      }
    }
    .file-list{
      padding: 16px 12px 0;
      .file-li{
        padding: 3px 12px;
        background: #FCFBFC;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 6px;
        .sidl-l{
          display: flex;
          align-items: center;
          cursor: pointer;
          img{
            width: 32px;
            margin-right: 8px;
          }
          span{
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
          }
        }
        .sidl-r{
          display: flex;
          .btn{
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 18px;
            margin-left: 28px;
            cursor: pointer;
            img{
              width: 12px;
              margin-top: -2px;
              margin-right: 3px;
            }

            // 附件上传中旋转动画
            .img-revolve{
              -webkit-transform: rotate(360deg);
              animation: rotation .7s linear infinite;
              -moz-animation: rotation .7s linear infinite;
              -webkit-animation: rotation .7s linear infinite;
              -o-animation: rotation .7s linear infinite;
            }
             @-webkit-keyframes rotation {
              from {
                -webkit-transform: rotate(0deg);
              }
              to {
                -webkit-transform: rotate(360deg);
              }
            }
          }
        }
      }
    }
    .submit-btn{
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: right;
      .cancel-btn{
        text-align: center;
        border-radius: 23px;
        color: #333333;
        line-height: 34px;
        border: 1px solid #666666;
        width: 114px;
        height: 34px;
        margin-right: 24px;
      }
      span{
        width: 114px;
        font-size: 14px;
        line-height: 34px;
        height: 34px;
        display: inline-block;
        cursor: pointer;
      }
    }
  }
}

.sping{
  width: 20px;
  height: 20px;
  position: absolute;
  left: 50%;
  top:100px;
  margin-top: -10px;
  margin-left: -10px;
}
</style>
