<script>
import { uploadObject } from "@/unit/cosUpload.js";
export default {
  // 数据对象
  data () {
    return {
      // 补充资料所需字段
      attachLoading: false,
      attachsingleLoad:false,
      attachspeed:0, // 速率
      attachprogress: 0, // 进度
      attachList: [], // 补充资料文件列表
      attachIndex: 0,  // 附件索引
    }
  },
  methods: {
    // 删除上传的附件
    onattachFileDel(i){
      this.attachList.splice(i, 1)
    },
    // 补充资料不限制格式
    attachBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5120;
      if (!isLt2M) {
        this.$message.error("作业附件大小需在5GB之内");
      }
      return isLt2M;
    },
    // 补充资料上传
    attachHandleChange(fileData) {
      this.attachprogress = 0
      this.attachspeed = 0
      this.attachLoading = true
      this.attachsingleLoad = true

      // 腾讯云对象存储上传文件
      const bucket = 'user/homework/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）

      // 腾讯云对象存储上传文件
      uploadObject(fileData.file, bucket, this.attachFileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.$message.success('上传成功');
          this.attachList.push({
            url: url,
            name: fileName,
            loading: false,
            type: this.returnType(url)
          })
        } else {
          this.$message.error("上传失败");
        }
        this.attachLoading = false
        this.attachsingleLoad = false
      });
    },
    // 附件类型返回
    returnType(url) {
      let lastIndex = url.lastIndexOf(".");
      let suffix = url.substring(lastIndex + 1);

      var imgFiles = ["jpg", "png", "gif", "jpeg"]; // 图片文件
      var videoFiles = ["mp4", 'MP4', 'Mp4', 'mP4']; // 视频文件
      var officeFiles = ["pdf", "doc", "docx", "xlsx", "xls", "txt"]; // 办公文件

      var type = "";
      if (imgFiles.indexOf(suffix) != -1) {
        type = 1;
      } else if (videoFiles.indexOf(suffix) != -1) {
        type = 2;
      } else if (officeFiles.indexOf(suffix) != -1) {
        type = 3;
      }
      return type;
    },
    // 文件上传更新进度和单文件上传初始化
    attachFileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
      this.attachprogress = progress
      this.attachspeed = speed
    },
    //点击图片唤起本地文件夹
    onclickAttachImg(index) {
      // 防抖
      if(!this.attachsingleLoad){
        this.$refs.attachfile.value = null
        this.attachIndex = index
        this.$refs.attachfile.click();
      } else {
          this.$message.warning('正在上传中，请稍后重试');
      }
    },
    //重新上传文件
    attachPreview(e) {
      let _this = this;
      let file = e.target.files[0];
      if (!file) {
        return;
      }

      const isLt2M = file.size / 1024 / 1024 < 5120;
      if (!isLt2M) {
        this.$message.error("作业附件大小需在5GB之内");
        return
      }

      _this.attachprogress = 0
      _this.attachspeed = 0

      _this.attachsingleLoad = true
      _this.attachList[_this.attachIndex].loading = true

      // 腾讯云对象存储上传文件
      const bucket = 'user/homework/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）

      // 腾讯云对象存储上传文件
      uploadObject(file, bucket, this.attachFileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          _this.$message.success('上传成功');
          _this.$set(_this, 'attachsingleLoad', false)
          
          let obj = {
            name: fileName,
            url: url,
            loading:false,
            type: this.returnType(url)
          }

          // 更新某个已上传的附件信息
          _this.$set(_this.attachList, _this.attachIndex, obj)
        } else {
          _this.$message.error("上传失败");
        }
      });
    },
  }
}
</script>

<style>

</style>